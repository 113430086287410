<template>
  <v-container fluid>
    <v-row>
      <v-col  class="d-flex justify-end align-end" align-self="end" align="end">
        <v-btn icon to="/">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <TheGuys></TheGuys>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TheGuys from '@/components/TheGuys';
export default {
  name: "Contact",
  components: {TheGuys}
}
</script>

<style scoped>

</style>
