<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" md="4">
        <h1>Die Jungs behind</h1>
      </v-col>
      <v-col sm="12" md="8">
        <h3>Cogrowing, das sind wir: Ingo und Alex.</h3>
        <br/>
        <p>Bei der Entdeckung des digitalen Feuers haben uns unsere beruflichen Wege und privaten Interessen zusammengeführt. Wir sind Freunde verschiedener Outdoor-Sportarten, lieben beide die Berge und haben immer spontane Ideen und ausgefallene Projekte parat. So findet man uns – wenn wir nicht gerade zusammen mit einem Kunden abgetaucht sind, um uns kreativ auszutoben und die Weltherrschaft an uns zu reißen – meinst auf dem Fahrrad unterwegs, an der Kletterwand oder den Sonnenaufgang aus dem VW Bus anschauend mit einem guten Kaffee in der Hand. Und natürlich immer mit einem Grinsen im Gesicht!</p>

        <p>Aber weshalb hat Ingo immer so schrecklich gute Laune, einen übermäßigen Optimismus und Tatendrang. Und warum bekommt Alex bei Outdoorthemen immer leuchtende Augen und weshalb sind komplizierte Projekte genau sein Ding?<br/>
        <br/>Schreib uns gerne an und lerne Ingo und Alex persönlich kennen und erfahre mehr über die @ymdod.</p>
        
<br/>
<br/>

        <div class="mt-5 imagecontainer">
          <div>
            <v-img class="mb-5" src="../assets/Kontakt_Ingo.png" contain max-height="90" max-width="50"></v-img>
            <v-btn rounded href="mailto:if@cogrowing.io">Sprich mit Ingo</v-btn>
          </div>
          <div>
            <v-img class="mb-5" src="../assets/Kontakt_Alex.png" contain max-height="90" max-width="50"></v-img>
            <v-btn rounded href="mailto:af@cogrowing.io">Sprich mit Alex</v-btn>        
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'TheGuys',
  props: {},
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>

.v-btn {
  border-color: #00a3ac !important;
  background-color: #00a3ac !important;
}

.imagecontainer {
  display: flex;
  justify-content: center;
}

.imagecontainer > div {
  display: flex;
  flex-direction: column;
align-items: center;
  margin:0 10px;
}

.imagecontainer .v-image {
  border-radius: 50%;
}

@media only screen and (min-width: 600px) {

  h1 {
    font-size: 60px;
  }

}
</style>
