<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h1>Datenschutz</h1>
      </v-col>
      <v-col align-self="end" align="end">
        <v-btn icon to="/">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <TrumpIpsum lines="3"></TrumpIpsum>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TrumpIpsum from "../components/PrivacyComponent";
export default {
  name: "Privacy",
  components: {TrumpIpsum}
}
</script>

<style scoped>

</style>
